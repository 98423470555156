<template>
 <div class="container-fluid">
   <div v-if="!getOrganisationAuthentication" class="my-auto mt-5">
     <div class="row mr-1 ml-1 mt-5">
       <div class="col text-danger">
         Please submit the organisation authentication code for your organisation provided by DNV personal.
       </div>
     </div>
     <div class="row ml-1 mr-1">
        <div v-if="alert" class="col-12 text-left pt-2">
          <OAlert 
            :variant="alertvariant" 
            :message="message" 
            :description="description"
            :dismissable="dismissable" 
            @dismiss="closeAlert"
          />
        </div>
    </div>
    <div class="row ml-1 mr-1">
      <div class="col-10 text-left p-2 pl-3 my-auto">
        <p><span class="avenir-demi text-small text-left">What is GDPR?</span></p>
        <p><span class="avenir-regular text-small text-left">GDPR is a new law on data protection and privacy that gives control over your personal information back to you. And although it's a European law, it'll still apply after Brexit. If you want to find out more about the detail of GDPR the Information Commissioner's Office (ICO) website is a good source, you can find that <a href="https://ico.org.uk/">here</a>.</span></p>

        <p><span class="avenir-demi text-small text-left">Who we share your information with?</span></p>
        <p><span class="avenir-regular text-small text-left">We do not share your information that we collect using this service with anyone. Anonymised survey responses will be used for benchmarking purposes in the future.</span></p>

        <p><span class="avenir-demi text-small text-left">How long will we keep your information?</span></p>
        <p><span class="avenir-regular text-small text-left">We'll only keep your information for as long as necessary (but no longer than we have to). This is done mainly to provide benchmarking services using anonymised data and in case when you need to access records.</span></p>

        <p><span class="avenir-demi text-small text-left">Your data rights</span></p>
        <p><span class="avenir-regular text-small text-left">You've trusted us with your details, so it's your right to have access to clear, easy to understand information about them. If any details are inaccurate, you've got the right to have them corrected. You also have new rights, like the right to erasure and the right to restrict processing, which give you more control over your data. For full details on your data rights and how to exercise them, please read our <a href="https://www.dnv.com/privacy/index.html">Privacy Policy</a>.</span></p>

        <p><span class="avenir-demi text-small text-left">Get in touch</span></p>
        <p><span class="avenir-regular text-small text-left">If you need any more info about your info and GDPR, please write to us at: <a href="mailto:isrs@dnv.com" target="_top">isrs@dnv.com</a></span></p>
      </div>
      <div class="float-left pt-3 col-12 text-left mb-5">
          <OForm
              v-model="details"
              :fields="fields"
              :inline="inline"
              :ok-button-text="okButtonText"
              @ok="onAuthenticate"
            />
        </div>
    </div>
   </div>
   <div v-else>
     <div class="mt-4 col-md-3 text-left">  
      <h3>My Profile</h3>
      <hr class="mt-n2">
    </div>
    <div class="shadow mt-2 p-3 col-5 float-left mb-5">
      <div class="row ml-2 mr-2 p-1">
        <div class="col-4 border border-info bg-sky-blue-light text-left">
          <span class="avenir-regular">Name</span>
        </div>
        <div class="col-8 border border-info text-left">
          <span class="avenir-regular"><b>{{getUser.FirstNames}} {{getUser.LastNames}}</b></span>
        </div>
      </div>
      <div class="row ml-2 mr-2 p-1">
        <div class="col-4 border border-info bg-sky-blue-light text-left">
          <span class="avenir-regular">Email</span>
        </div>
        <div class="col-8 border border-info text-left">
          <span class="avenir-regular"><b>{{getUser.EmailAddress}}</b></span>
        </div>
      </div>
      <div class="row ml-2 mr-2 p-1">
        <div class="col-4 border border-info bg-sky-blue-light text-left">
          <span class="avenir-regular">Company Name</span>
        </div>
        <div class="col-8 border border-info text-left">
          <span class="avenir-regular"><b>{{getAuthenticatedOrganisation.OrganisationName}}</b></span>
        </div>
      </div>
      <div class="row ml-2 mr-2 p-1">
        <div class="col-4 border border-info bg-sky-blue-light text-left">
          <span class="avenir-regular">Department Code</span>
        </div>
        <div class="col-8 border border-info text-left">
          <span v-if="getUser.OrganisationRef" class="avenir-regular"><b>{{getUserOrganisation.OrganisationDetails[0].DepartmentCode}}</b></span>
        </div>
      </div>
      <div class="row ml-2 mr-2 p-1">
        <div class="col-4 border border-info bg-sky-blue-light text-left">
          <span class="avenir-regular">Department Name</span>
        </div>
        <div class="col-8 border border-info text-left">
          <span v-if="getUser.OrganisationRef" class="avenir-regular"><b>{{getUserOrganisation.OrganisationDetails[0].DepartmentName}}</b></span>
        </div>
      </div>
      <div class="row ml-2 mr-2 p-1">
        <div class="col-4 border border-info bg-sky-blue-light text-left">
          <span class="avenir-regular">Location</span>
        </div>
        <div class="col-8 border border-info text-left">
          <span v-if="getUser.OrganisationRef" class="avenir-regular"><b>{{getUserOrganisation.OrganisationDetails[0].Location}}</b></span>
        </div>
      </div>
    </div>
    <div class="shadow mt-2 p-2 ml-5 col-6 float-left mb-5">
      <div v-if="getAuthenticatedOrganisation.OrganisationDetails.length != 0" class="row ml-2 mr-2 p-1 bg-sky-blue-lightest">
        <div class="col text-left p-2">
          <span class="avenir-regular">Select or Update your department from the dropdown list below before beginning the survey.</span>
        </div>
      </div>
      <div class="row ml-2 mr-2 p-1 bg-sky-blue-lightest">
        <div class="col text-left p-2">
         <select v-if="getAuthenticatedOrganisation.OrganisationDetails.length != 0" name="department" id="department" v-model="departmentId" class="selectpicker p-2" @change="updateDepartment">
             <option value="0">Select...</option>
             <option v-for="item in getAuthenticatedOrganisation.OrganisationDetails" v-bind:key="item.ID" :value="item.ID">{{item.DepartmentName}} ({{item.DepartmentCode}})</option>
          </select>
          <span v-else class="avenir-regular">There are no departments listed for this organisation, please add one by clicking the button below.</span>
        </div>
      </div>
      <div v-if="getAuthenticatedOrganisation.OrganisationDetails.length != 0" class="row ml-2 mr-2 p-1 bg-sky-blue-lightest">
        <div class="col text-left p-2">
          <span class="avenir-regular">If your department is not listed in the dropdown box then please add one by clicking the button below.</span>
        </div>
      </div>
      <div class="row ml-2 mr-2 p-1 bg-sky-blue-lightest">
        <div class="col text-left p-2">
          <b-button 
            variant="primary" 
            :class="size"
            @click="visible = true" 
            class="px-4"
          >
          <i class="fal mr-2" :class="departmentbtniconClass"></i>
            Add a new Department
          </b-button>
          <!--<b-button :variant="departmentbtnvariant" :text="departmentbtntext" :class="size">
            <i class="fal mr-2" :class="departmentbtniconClass"></i>
            {{departmentbtntext}}
          </b-button>-->
        </div>
      </div>
      <OModal 
        v-if="visible"
        :hideFooter = true
        :maxWidth="width" 
        @close="visible = false" 
        :clickOutside="clickoutside"
      >
        <template slot="title">
          <h3 class="m-0">Add new department information</h3>
        </template>
        <section slot="body" style="min-height: 200px">
            <div class="row ml-1 mr-1">
              <div class="float-left pt-3 col-12 text-left mb-5">
                  <OForm
                      ref = "departmentForm"
                      v-model="departmentdetails"
                      :fields="departmentfields"
                      :inline="inline"
                      :ok-button-text="savedepartmentbtntext"
                      @ok="saveDepartment"
                      @cancel="resetFields"
                    />
                </div>
            </div>
        </section>
      </OModal>
    </div>
    <div>
      <div class="mt-4 col-md-3 text-left">
        <h3>My Survey</h3>
        <hr class="mt-n2">
      </div>
      <div v-if="getUser.OrganisationRef" class="shadow m-3 p-3 col-11">
        <div class="row border border-bottom p-1">
          <div class="col-2">
            <span class="avenir-demi">Survey ID</span>
          </div>
          <div class="col-2">
            <span class="avenir-demi">Survey Start Date</span>
          </div>
          <div class="col-2">
            <span class="avenir-demi">Survey Status</span>
          </div>
          <div class="col-2">
            <span class="avenir-demi">Survey Last Updated</span>
          </div>
          <div class="col-2">
            <span class="avenir-demi">Survey Completed Date</span>
          </div>
          <div class="col-2">
          </div>
        </div>
        <div v-if="getUserSurveyList.length != 0">
          <div v-for="survey in getUserSurveyList" v-bind:key="survey.ID" class="row border border-bottom p-1">
            <div class="col-2">
              <span class="avenir-regular">{{survey.ID}}</span>
            </div>
            <div class="col-2">
              <span class="avenir-regular">{{formatDate(survey.createdAt)}}</span>
            </div>
            <div class="col-2">
              <span class="avenir-regular">{{survey.SurveyStatus}}</span>
            </div>
            <div class="col-2">
              <span class="avenir-regular">{{formatDate(survey.updatedAt)}}</span>
            </div>
            <div class="col-2">
              <span v-if="survey.SurveyCompletedDate" class="avenir-regular">{{formatDate(survey.SurveyCompletedDate)}}</span>
              <span v-else class="avenir-regular">--</span>
            </div>
            <div v-if="survey.SurveyStatus == 'Completed'" class="col-2">
              <i class="fas fa-check-square"></i>
            </div>
            <div v-else class="col-2">
              <b-button :variant="variant" :text="text" @click="startSurvey" :class="size">
                <i class="fal mr-2" :class="iconClass"></i>
                {{text}}
              </b-button>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row border border-bottom p-1">
            <div class="col-2">
              <span class="avenir-regular">--</span>
            </div>
            <div class="col-2">
              <span class="avenir-regular">--</span>
            </div>
            <div class="col-2">
              <span class="avenir-regular">--</span>
            </div>
            <div class="col-2">
              <span class="avenir-regular">--</span>
            </div>
            <div class="col-2">
              <span class="avenir-regular">--</span>
            </div>
            <div class="col-2">
              <b-button :variant="variant" :text="text" @click="startSurvey" :class="size">
                <i class="fal mr-2" :class="iconClass"></i>
                {{text}}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="mt-4 ml-2 col-5 text-left p-2 border border-warning bg-red-even-lighter">
          <i class="fal mr-2 fa-exclamation-triangle warning"></i>
          <span class="avenir-regular"> Please select a department in order to activate the survey.</span>
        </div>
      </div>
    </div>
   </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default Vue.extend({
  name : 'overview',
  data () {
    return {
      alertvariant: "danger",
      message: "",
      description: "",
      dismissable: true,
      alert:false,
      variant: "primary",
      iconClass: "fa-edit",
      text: "Go to Survey",
      departmentbtnvariant: "primary",
      departmentbtniconClass: "fa-plus",
      savedepartmentbtntext: "Submit",
      size: "btn-xs p-1",
      visible: false,
      width: 800,
      clickoutside: true,
      departmentId : "1",
      inline: false,
      fields: [
        {
          name: 'OrganisationAccessCode',
          heading: 'Access Code',
          type: 'input',
          invalidFeedback: 'Please enter the access code',
          required: true
        },
        {
          name: "Consent",
          heading: "Consent to Collection and Use of Personal Information",
          type: "checkbox",
          value: true,
          invalidFeedback: "Please provide your consent before the survey begins.",
          required: true
        }
      ],
      okButtonText: 'Authenticate',
      details: {
        OrganisationAccessCode :"",
        Consent : false
      },
      departmentfields: [
        {
          name: 'DepartmentCode',
          heading: 'Department Code',
          type: 'input',
          invalidFeedback: 'Please enter the department code',
          required: true
        },
        {
          name: 'DepartmentName',
          heading: 'Department Name',
          type: 'input',
          invalidFeedback: 'Please enter the department name',
          required: true
        },
        {
          name: 'Location',
          heading: 'Country',
          type: 'input',
          invalidFeedback: 'Please enter the country for the department',
          required: true
        },
        {
          name: 'Address',
          heading: 'Address',
          type: 'input',
          invalidFeedback: 'Please enter the Address',
          required: true
        }
      ],
      departmentdetails: {
        DepartmentCode :"",
        DepartmentName: "",
        Location : "",
        Address : ""
      }
    }
  },
  mounted () {

    //Remove the unwanted cancel button from form.  Does not seem to be an option to do this in the component
    const cancelButton = document.getElementById('btnCancel')
    if (cancelButton) {
      cancelButton.style.display = 'none'
    }

    if(this.getUser.OrganisationRef != null)
    {
      this.$store.dispatch('organisation/getUserOrganisation',{ OrganisationRef :  this.getUser.OrganisationRef})
          .then(() => {
              console.log('dispatch for user organisation called')
          });
      this.$store.dispatch('survey/getUserSurveyList',{ UserID :  this.getUser.ID})
          .then(() => {
              console.log('dispatch for user survey list called')
          });
      this.departmentId = this.getUserOrganisation.OrganisationDetails[0].ID
    }
  },
  computed : {
    ...mapGetters('user', ['getUser']),
    ...mapGetters('organisation',['getUserOrganisation']),
    ...mapGetters('survey',['getOrganisationAuthentication','getAuthenticatedOrganisation','getUserSurveyList'])
  },
  methods :{
    ...mapActions('survey', ['checkOrganisationAuthentication']),
    ...mapActions('organisation', ['updateUserDepartment', 'addNewDepartment']),
    async onAuthenticate () {
      try{
        if(this.details.Consent)
        { 
            const orgCode =  this.details.OrganisationAccessCode
            await this.checkOrganisationAuthentication({organisationAccessCode : orgCode})
        }
        else{
          this.alert = true
          this.message = "Consent!!"
          this.description = "Please provide your consent before the survey begins."
        }
      }
      catch(e){
        console.log(e)
        this.alert = true
        this.message = "Access Code Invalid"
        this.description = "Please contact DNV Admin for correct ogranisation access code."
      }
       
    },
    async updateDepartment(){

      if(this.departmentId!=0)
      {
        await this.updateUserDepartment({ UserID : this.getUser.ID, OrganisationRef : this.departmentId})
        window.location.reload();
      }
    },
    async saveDepartment(){
      await this.addNewDepartment({
          DepartmentCode :  this.departmentdetails.DepartmentCode, 
          DepartmentName: this.departmentdetails.DepartmentName,
          Location : this.departmentdetails.Location,
          Address : this.departmentdetails.Address,
          OrganisationID : this.getAuthenticatedOrganisation.ID
      })
      await this.checkOrganisationAuthentication({organisationAccessCode : this.getAuthenticatedOrganisation.OrganisationAccessCode})
      this.departmentdetails.DepartmentCode=""
      this.departmentdetails.DepartmentName=""
      this.departmentdetails.Location = ""
      this.departmentdetails.Address = ""
      this.visible=false
      location.reload()
    },
    resetFields(){
      this.departmentdetails.DepartmentCode=""
      this.departmentdetails.DepartmentName=""
      this.departmentdetails.Location = ""
      this.departmentdetails.Address = ""
    },
    startSurvey () { 
      if(this.getOrganisationAuthentication){
              this.$router.push('/surveyNotice')
            }
      //this.$router.push({ path: '/surveyStart' })
    },
    closeAlert(){
      this.alert = false;
      this.message = ""
      this.description=""
    },
    formatDate(val) {
      return moment(val).format('DD/MM/YYYY')
    }
  }
})
</script>

<style scoped>

</style>
